import React from 'react';
import PropTypes from 'prop-types';

const sizeMap = {
  sm: 'h-6',
  md: 'h-12',
  lg: 'h-16',
  xl: 'h-20',
};

function Footer({ className = '', size, position = 'fixed', ...rest }) {
  const heightClass = `${sizeMap[size]} flex-grow-0 flex-shrink-0`;
  const needsPlaceholder = position === 'fixed' || position === 'absolute';

  return (
    <>
      {needsPlaceholder && <div className={heightClass} />}
      <div className={`w-full bottom-0 flex ${position} ${heightClass} ${className}`} {...rest} />
    </>
  );
}

Footer.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
  position: PropTypes.oneOf(['fixed', 'static', 'absolute']),
  children: PropTypes.node,
};


export { Footer };
