import React from 'react';
import * as PropTypes from 'prop-types';
import Link from 'next/link';
import { Button, PrimaryButton } from './buttons/Button';


function createLinkButton(TheButton) {
  function LinkButton({ href, as, ...rest }) {
    return (
      <Link href={href} as={as}>
        <a className="no-underline">
          <TheButton {...rest} />
        </a>
      </Link>
    );
  }

  LinkButton.propTypes = {
    href: PropTypes.string.isRequired,
    as: PropTypes.string,
  };

  return LinkButton;
}

export const LinkButton = createLinkButton(Button);
export const PrimaryLinkButton = createLinkButton(PrimaryButton);
