import React from 'react';
import * as PropTypes from 'prop-types';
import { Page } from '../../core/Page';
import { AppBar } from '../AppBar';
import { Content } from '../../core/layout/Content';
import { AppFooter } from '../AppFooter';

export function DefaultLayout({ children }) {
  return (
    <Page>
      <AppBar.Memo />
      <Content className="justify-center items-start">
        {children}
      </Content>
      <AppFooter />
    </Page>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.node,
};

export const getDefaultLayout = (page) => <DefaultLayout>{page}</DefaultLayout>;
