import React from 'react';
import { PrimaryLinkButton } from '../src/components/core/LinkButton';
import { EmptyState } from '../src/components/core/EmptyState';
import { BodyM, BodyS } from '../src/components/core/Typography';
import { SadIcon } from '../src/components/app/icons';
import { getDefaultLayout } from '../src/components/app/layouts/DefaultLayout';

function HomeNotImplementedYet() {
  return (
    <EmptyState
      className="h-auto m-auto mt-20"
      description={(
        <BodyS strong>
          You are too early here, there is nothing yet!
        </BodyS>
      )}
      icon={
        <SadIcon size={100} className="text-warning" />
      }
    >
      <BodyM>
        Oh and if you are the admin, you know what to do!
      </BodyM>

      <PrimaryLinkButton href="/system">Go to Admin Login</PrimaryLinkButton>
    </EmptyState>
  );
}

export default function Home() {
  return (
    <HomeNotImplementedYet />
  );
}

Home.getLayout = getDefaultLayout;
