import React from 'react';
import * as PropTypes from 'prop-types';

const sizeMap = {
  sm: 'h-8',
  md: 'h-12',
  lg: 'h-16',
  xl: 'h-20',
};

function Header({ className = '', size, position = 'fixed', ...rest }) {
  const heightClass = `${sizeMap[size]} flex-grow-0 flex-shrink-0`;
  const needsPlaceholder = position === 'fixed' || position === 'absolute';
  return (
    <>
      <div className={`${className} w-full flex ${position} ${heightClass}`} {...rest} />
      {needsPlaceholder && <div className={heightClass} />}
    </>
  );
}

Header.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
  position: PropTypes.oneOf(['fixed', 'static', 'absolute']),
  children: PropTypes.node,
};

export { Header };
