import React from 'react';
import * as PropTypes from 'prop-types';
import { NonIdealState } from '@blueprintjs/core';

export function EmptyState({ icon, title, description, action, children, className }) {
  return (
    <NonIdealState
      icon={icon} title={title} description={description} action={action}
      className={className}
    >
      {children}
    </NonIdealState>
  );
}

EmptyState.propTypes = {
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  title: PropTypes.node,
  description: PropTypes.node,
  action: PropTypes.element,
  children: PropTypes.node,
  className: PropTypes.string,
};
