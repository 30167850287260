export const appConfig = {
  appName: process.env.APP_NAME,
  apiBaseUrl: process.env.API_BASE_URL,
};

export const autouwConfig = {
  apiKey: process.env.AUTOUW_API_KEY,
  apiSecret: process.env.AUTOUW_API_SECRET,
  apiUrl: process.env.AUTOUW_API_URL,
  appUrl: process.env.AUTOUW_APP_URL,
};
