import React from 'react';
import { Footer } from '../core/layout/Footer';
import { BodyS } from '../core/Typography';

function AppFooter() {
  return (
    <Footer size="sm" className="bg-appTitleBackground shadow-md items-center px-2">
      <BodyS className="m-0 text-appTitleText">
        © Copyright Clik.ai 2020
      </BodyS>
    </Footer>
  );
}

export { AppFooter };
