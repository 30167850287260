import React from 'react';
import { Header } from '../core/layout/Header';
import { appConfig } from '../../config';
import { HeadingS } from '../core/Typography';

function AppBar() {
  return (
    <Header className="bg-appTitleBackground shadow-md items-center px-2" size="md">
      <HeadingS className="m-0 text-appTitleText">
        {appConfig.appName}
      </HeadingS>
    </Header>
  );
}

AppBar.Memo = React.memo(AppBar);

export { AppBar };
