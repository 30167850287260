import React from 'react';
import * as PropTypes from 'prop-types';

function Content({ className = '', ...rest }) {
  return (
    <div className={`h-full w-full min-h-0 flex overflow-auto ${className}`} {...rest} />
  );
}

Content.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export { Content };
