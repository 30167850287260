import { FaRegFrown } from 'react-icons/fa';
import { FaUserFriends } from 'react-icons/fa';
import { FaRegBell } from 'react-icons/fa';
import { FaInfoCircle } from 'react-icons/fa';
import { FaRegCommentAlt } from 'react-icons/fa';

export const SadIcon = FaRegFrown;
export const UsersIcon = FaUserFriends;
export const NotificationsIcon = FaRegBell;
export const InfoIcon = FaInfoCircle;
export const CommentIcon = FaRegCommentAlt;
